import * as yup from 'yup';
import {globalT} from '../../lang';
import { CountryLight } from '../../commons/helpers/CountryManager';
import MemberTypeEnum from '../../commons/enums/MemberTypeEnum';

const regex = {
    number: /^\d+$/,
    period: /^\d{4}-\d{4}$/,
    url: /\b((https?|ftp):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(\/[^\s]*)?\b/,
    urlWithHttp: /\b((https?|ftp):\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(\/[^\s]*)?\b/,
}

export const generalRegex = [
    {
        country: 'CA',
        telephone: /^\d+$/,
        postalCode: /^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/,
        address: /^[a-zA-Z0-9#\s,'-]+$/,///^\d+\s[A-Za-z\s]+$/
    },
    {
        country: 'XX',
        telephone: /^\d+$/,
        address: /^[a-zA-Z0-9#\s,'-]+$/,
        postalCode: /^[A-Za-z\d\s-]+$/
    },
    {
        country: null,
        username: /^[A-Za-z0-9àâçéèêëîïôûùüÀÂÇÉÈÊËÎÏÔÛÙÜ][A-Za-z0-9 àâçéèêëîïôûùüÀÂÇÉÈÊËÎÏÔÛÙÜ',.-]+$/,
        globalname: /^[A-Za-z0-9àâçéèêëîïôûùüÀÂÇÉÈÊËÎÏÔÛÙÜ][A-Za-z0-9 àâçéèêëîïôûùüÀÂÇÉÈÊËÎÏÔÛÙÜ',.-]+$/,
        acronym: /^[A-Z ]*$/,
    }

]

export const language = yup.object().required(globalT('form.errors.required'));
export const region = yup.object().required(globalT('form.errors.required'));
export const required = yup.object().required(globalT('form.errors.required'));
export const country = yup.object().required(globalT('form.errors.required')).nullable();
export const textarea = yup.string().required(globalT('form.errors.required')).nullable();
export const email = yup.string().required(globalT('form.errors.required')).email(globalT('form.errors.email'));
export const nullableEmail = yup.string().email(globalT('form.errors.email')).nullable();
export const name = yup.string().required(globalT('form.errors.required'));
export const username = yup.string().matches(generalRegex.find(gr => gr.country == null)?.username || /(.*?)/, globalT('form.errors.name')).required(globalT('form.errors.required'));
export const period = yup.string().matches(regex.period, globalT('format.wrong')).required(globalT('form.errors.required'));
export const globalname = yup.string().matches(generalRegex.find(gr => gr.country == null)?.globalname || /(.*?)/, globalT('form.errors.name')).required(globalT('form.errors.required'));
export const acronym = yup.string().matches(generalRegex.find(gr => gr.country == null)?.acronym || /(.*?)/, globalT('form.errors.acronym.validate')).required(globalT('form.errors.required'));
export const canadianPhone = yup.string().matches(generalRegex.find(gr => gr.country == 'CA')?.telephone || /^\d+$/, globalT('form.errors.canadianPhone')).required(globalT('form.errors.required'));

export const telephone = yup.string().when('country', {
    is: (country: CountryLight) => country?.alpha2Code === 'CA',
    then: yup.string().required(globalT('form.errors.required')).matches(generalRegex.find(gr => gr.country === 'CA' )?.telephone || /^\d+$/, globalT('form.errors.canadianPhone')),
    otherwise: yup.string().matches(/^\d+$/, globalT('form.errors.telephone')).required(globalT('form.errors.required')),
});

export const nullableTelephone = yup.string().when('country', {
    is: (country: CountryLight) => country?.alpha2Code === 'CA',
    then: yup.string().matches(generalRegex.find(gr => gr.country === 'CA' )?.telephone || /^\d+$/, {message: globalT('form.errors.canadianPhone'), excludeEmptyString:true}),
    otherwise: yup.string().matches(/^\d+$/, {message: globalT('form.errors.telephone'), excludeEmptyString:true}),
});

export const postalCode = yup.string().when('country', {
    is: (country: CountryLight) => country?.alpha2Code === 'CA',
    then: yup.string().matches(generalRegex.find(gr => gr.country === 'CA' )?.postalCode || /^[A-Za-z\d\s-]+$/, globalT('form.errors.postalCode')),
    otherwise: yup.string().matches(/^[A-Za-z\d\s-]+$/, globalT('form.errors.postalCode')),
});

export const address = yup.string().when('country', {
    is: (country: CountryLight) => country?.alpha2Code === 'CA',
    then: yup.string().matches(generalRegex.find(gr => gr.country === 'CA' )?.address || /^[a-zA-Z0-9\s,'-]+$/, globalT('form.errors.address')),
    otherwise: yup.string().matches(/^[a-zA-Z0-9\s,'-]+$/, globalT('form.errors.address')),
});

export const code = yup.string().required(globalT('form.errors.required'));

export const password = yup.string().required(globalT('form.errors.required'))
    .min(8, globalT('password.minLength'))
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, { message: globalT('password.validation') });

export const amount = yup
    .string()
    .test('numberCheck', globalT('form.errors.price'), (val: string | undefined) => val ? Number(val) > 0 : true).nullable();

export const amountRequired = amount.concat(amount).required(globalT('form.errors.required'));

var date = new Date();
date.setDate(date.getDate() - 1);
export const dateGreaterOrEqualsToToday = yup
    .date()
    .min(date, globalT('form.errors.date.incorrect')).typeError(globalT('form.errors.date.incorrect'))

export const expirationDate = yup
    .date().when('memberType', {
        is: (memberType: { value: MemberTypeEnum, label: string }) => {
            return memberType?.value.value === 'VISITOR'
        },
        then: yup.date().min(new Date(), globalT('form.errors.date.incorrect')).typeError(globalT('form.errors.date.incorrect')).required(globalT('form.errors.required')),
        otherwise: yup.date().nullable(),
    })
    

export const dateLessOrEqualsToToday = yup
    .date()
    .max(new Date(), globalT('form.errors.date.incorrect')).typeError(globalT('form.errors.date.incorrect'))
    .required(globalT('form.errors.required'));

export const birthDate = yup
    .date()
    .min(new Date(1900, 1, 1), globalT('form.errors.birthDate'))
    .max(new Date(), globalT('form.errors.birthDate'))
    .required(globalT('form.errors.birthDate'))
    .typeError(globalT('form.errors.date.incorrect'))

export const phone = yup
    .string()
    .required(globalT('form.errors.phone'))
    .matches(/^\d+$/, globalT('form.errors.phone'))
    .test('len', globalT('form.errors.phone'), (val: string | undefined) => val ? val.length >= 4 : false);

export const url = yup.string()
    .matches(regex.url, globalT('form.errors.url'))
    .required(globalT('form.errors.required'));

export const urlWithHttp = yup.string()
    .matches(regex.urlWithHttp, globalT('form.errors.url'))
    .required(globalT('form.errors.required'));

export const type = yup.string().required(globalT('form.errors.required'));
export const description = yup.string().required(globalT('form.errors.required'));
export const numberDefault = yup.number().required(globalT('form.errors.required'));
export const integerDefault = yup
    .number()
    .required(globalT('form.errors.required'))
    .positive(globalT('form.errors.number.incorrect'));
export const stringDefault = yup.string().required(globalT('form.errors.required'));
export const objectDefault = yup.object().required(globalT('form.errors.required'));
