import dayjs, {Dayjs} from "dayjs";
import Community from "./Community";
import DeathEvent from "./DeathEvent";
import ContributionStatusEnum from "../enums/ContributionStatusEnum";

export default class Contribution {
    public id: string
    public community: Community
    public event: DeathEvent
    public fees: number
    public platformFees: number
    public deadline: Dayjs
    public status: ContributionStatusEnum
    public effectiveMembers: number
    public amountCollected: number
    public penalityCollected: number
    public penalityDue: number
    public unitFees: number
    public receipt: string
    public createdAt: Dayjs
    public updatedAt: Dayjs

    constructor(data: any) {
        this.id = data.reference
        this.fees = data.fees
        this.receipt = data.receipt || null
        this.amountCollected = data.amountCollected
        this.penalityCollected = data.penalityCollected
        this.penalityDue = data.penalityDue
        this.unitFees = data.unitFees
        this.platformFees = data.platformFees
        this.effectiveMembers = data.effectiveMembers
        this.community = new Community(data.community)
        this.event = new DeathEvent(data.event)
        this.status = ContributionStatusEnum.tryParse(data.status)
        this.deadline = dayjs(data.deadline).subtract(1, 'day').hour(23)
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
    }
}
