import dayjs, {Dayjs} from "dayjs";

import Member from "./Member";
import Community from "./Community";
import TransferStatusEnum from "../enums/TransferStatusEnum";

export default class Transfer {
    public id: string
    public member: Member
    public sourceCommunity: Community
    public targetCommunity: Community
    public status: TransferStatusEnum
    // public type: TransferTypeEnum
    public createdAt: Dayjs
    public updatedAt: Dayjs
    public amount: number
    public paidAt: Dayjs | null

    constructor(data: any) {
        this.id = data.reference
        this.member = new Member(data.member)
        this.sourceCommunity = new Community(data.sourceCommunity)
        this.targetCommunity = new Community(data.targetCommunity)
        this.status = TransferStatusEnum.tryParse(data.status)
        // this.type = TransferTypeEnum.tryParse(data.type)
        this.createdAt = dayjs(data.createdAt)
        this.updatedAt = dayjs(data.updatedAt)
        this.amount = data.amount ?? null;
        this.paidAt = data.paidAt ? dayjs(data.paidAt) : null;
    }
}
