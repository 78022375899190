import NotificationManager from "../../components/notifications/NotificationManager";
import {globalT} from "../../lang";
import {FieldError, FieldErrors} from "react-hook-form/dist/types/errors";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import config from "../../config";
import {AxiosRequestConfigExtended, FetchResourceWatcher} from "../../network/network.types";
import {Dispatch, SetStateAction} from "react";
import {AxiosResponse} from "axios";

export const longBarSymbol = '⚊'

/**
 * Check if a given value is a real object
 * E.g: {name: "Jack"} is a real object
 * @param value
 */
export const isLiteralObject = (value: any) => !!value && value.constructor === Object;

/**
 * NaN !== NaN ==> true
 * @param v
 */
export function valueIsNaN(v: unknown) { return Number.isNaN(v); }

/**
 * @param b
 */
export const parseLargeBoolean = (b: any): boolean | undefined => {
    if([1, 'true', 'TRUE', true].includes(b)) return true;
    return [0, 'false', 'FALSE', false].includes(b) ? false : undefined;
}


/**
 * Stringify digit. If digit less than 10, prefix with 0
 * @param value
 */
export const stringifyDigit = (value: number) => value < 10 ? `0${value}` : value;

/**
 * Check if a something is numeric.
 * Optionally allow a filter to allow only positive or negative number
 * @param num
 * @param sign null || '+' || '-'
 * @param strict Indicate if num must be of type number
 * @returns {boolean}
 */
export const isNumeric = (num: any, sign: null | '+' | '-' = null, strict = false) => {
    const _isNumeric = strict
        ? typeof num === 'number' && !valueIsNaN(Number(num))
        : (typeof num === 'number' || typeof num === "string" && num.trim() !== '') && !valueIsNaN(Number(num));
    if(sign === null) return _isNumeric;
    return sign === '+' ? _isNumeric && num >= 0 : _isNumeric && num <= 0;
};

/**
 * Generate a unique id
 * @returns {string}
 */
export const generateId = (): string => new Date().valueOf().toString(36) + Math.random().toString(36).substring(2);

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min: number, max: number) {
    const _min = Math.ceil(min);
    const _max = Math.floor(max);
    return Math.floor(Math.random() * (_max - _min + 1)) + _min;
}


/**
 * Round a number to given decimal
 * @param num (undefined for compatibility)
 * @param decimal > 0
 */
export const round = (num: number | undefined, decimal = 2): number => {
    if (decimal <= 0 || num === undefined)
        return NaN;
    const divider = Number(`1${Array.from({length: decimal}, () => '0').join('')}`);

    return Math.round((num + Number.EPSILON) * divider) / divider;
}

/**
 * Convert an UTC date to locale date
 * @param utcDate
 */
export const utcToLocaleDate = (utcDate: string): string => {
    const _utcDate = utcDate.endsWith('Z') ? utcDate : `${utcDate}Z`;
    return new Date(_utcDate).toString();
}

/**
 * Fetch a file path from backend
 * @param file
 */
export const getFilePath = (file: string | null | undefined): string => {
    if (file) {
        return file.startsWith('http') && file.includes(':')
            ? file
            : `${config.rpnApiUrl}/${file}`;
    }

    return "";
}

/**
 * Format date for display
 * @param date
 * @param format
 * @param emptyValue
 */
export const formatDate = (date: dayjs.Dayjs | Date | string | undefined | null, format = 'lll', emptyValue = '⚊', convert = true) => {
    if (date) {
        if(convert) {
            dayjs.extend(timezone);
            const _date = dayjs.isDayjs(date) ? date.tz('America/Toronto') : dayjs(date).tz('America/Toronto');
            return _date.isValid() ? _date.format(format) : emptyValue;
        } else {
            const _date = dayjs.isDayjs(date) ? date : dayjs(date);
            return _date.isValid() ? _date.format(format) : emptyValue;
        }
    }
    return emptyValue;
}

export const numberFormatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
});

export const formatNumber = (number: any, unit?: string, placement: 'left' | 'right' = 'right'): string => {
    // eslint-disable-next-line eqeqeq
    if (Number(number) || number == 0) {
        let _n = numberFormatter.format(number).toString();
        // Remove " €" from number to allow other units
        _n = _n.replace(/\s€/, '');
        // Increase space size for better visibility
        _n = _n.replace(/\s/ig, '  ');
        let _unit = null;
        if(unit === undefined) {
            _unit = '€';
        } else {
            _unit = unit === '' ? '' : `${unit}`;
        }
        if (_unit !== '')
            _unit = `${_unit} `

        return placement === 'left' ? `${_unit}${_n}` : `${_n}${_unit}`;
    }
    return `⚊`;
}

/**
 * Bind params to a given url
 * @param to
 * @param params
 */
export const joinUrlWithParams = (to: string, params: Array<{ param: string; value: any }>) => {
    let url = to;
    params.forEach(param => {
        url = url.replace(`:${param.param}`, `${encodeURIComponent(param.value)}`);
    });

    return url;
};

/**
 * Shortcut of joinUrlWithParams for Id
 * @param to
 * @param id
 */
export const joinUrlWithParamsId = (to: string, id: string | number) => joinUrlWithParams(to, [{ param: 'id', value: id }]);

/**
 * Transform an object to form data
 * Handle the special case of array of input.
 *    And for that, just need to have in object keys like file[]1, file[]2, etc. it'll be transformed to one key of the form data
 * @param obj
 * @param skipTrueNullishValue Whether to include or not null, undefined, NaN
 */
export const objectToFormData = (obj: object, skipTrueNullishValue = true) => {
    let formData = new FormData();
    if (obj instanceof FormData) {
        formData = obj;
    } else {
        Object.entries(obj)
            .forEach(item => {
                const [key, value] = item;
                if (skipTrueNullishValue && [null, NaN, undefined].includes(value))
                    return;

                if (key.includes('[]')) {
                    const _key = key.split('[]')[0];
                    formData.append(`${_key}[]`, value);
                }
                else formData.append(key, value);
            });
    }
    return formData;
};

export type DeepMapObject = (
    obj: Array<any> | Record<string | number, any>,
    validateKey?: (key: string | number) => boolean,
    transformKey?: (key: string | number) => string | number,
    transformValue?: (value: any) => any,
) => Array<any> | Record<string | number, any>;

/**
 * Deep mapping an object and apply some transformation
 * @param obj A variable of type array or raw object
 * @param validateKey function to filter key to apply transformations
 * @param transformKey function to transform an key to a new one
 * @param transformValue function to transform a value to a new one
 */
export const deepMapObject: DeepMapObject = (
    obj,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validateKey = (key: string | number) => true,
    transformKey = (key: string | number) => key,
    transformValue = (value: any) => value
) => {
    let rtn = obj;
    if (typeof (obj) === 'object') {
        if (obj instanceof Array) {
            rtn = obj.map(item => deepMapObject(item, validateKey, transformKey, transformValue));
        } else {
            rtn = {};
            Object.entries(obj).forEach(([key, value]) => {
                if (validateKey(key)) {
                    // apply the change on the string
                    const newKey = transformKey(key);

                    // Falsy or primitive value
                    if (!value || typeof value !== 'object')
                        // @ts-ignore
                        rtn[newKey] = transformValue(value);

                        // nested object
                    // @ts-ignore
                    else rtn[newKey] = deepMapObject(value, validateKey, transformKey, transformValue);
                }
                    // Continuous looping in case of object type
                // @ts-ignore
                else rtn[key] = !value || typeof value !== 'object'
                    ? value
                    : deepMapObject(value, validateKey, transformKey, transformValue);
            });
        }
    }
    return rtn;
}

export function stringToCamelCase(str: string | number) {
    return `${str}`.replace(/(_\w)/g, k => k[1].toUpperCase())
}

// export function stringToCamelCase(str: string) {
//   return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
//     return index == 0 ? word.toLowerCase() : word.toUpperCase();
//   }).replace(/\s+/g, '');
// }

// export function stringToCamelCase(str) {
// 	return str.replace(/[\s_]+(\w)/g, '$1');
// }

export function capitalize(str: string) {
    return str.replace(str[0], str[0].toUpperCase())
}

/**
 * Convert a value to camelCase
 * @param value
 */
export function toCamelCase(value: any) {
    if (typeof value === 'string') {
        return stringToCamelCase(value);
    }

    return deepMapObject(value, undefined, stringToCamelCase);
}

/**
 * Convert a string to snake_case
 * @param str
 */
export function stringToSnakeCase(str: string | number) {
    return `${str}`.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_');
}

/**
 * Convert a value to snake case
 * @param value
 */
export function toSnakeCase(value: any): string | Record<string | number, any> {
    if (typeof value === 'string') {
        return stringToSnakeCase(value);
    }

    return deepMapObject(value, undefined, stringToSnakeCase);
}


type MapDataToQueryStringType = {
    data: object,
    url?: string,
    removeTrashValue?: boolean,
    transformToUrl?: boolean,
    transformToSnakeCase?: boolean,
};

export const mapDataToQueryString = ({
    data,
    url = '',
    removeTrashValue = true,
    transformToUrl = false,
    transformToSnakeCase = true
    }: MapDataToQueryStringType): { params: object, url: string } => {
    let _url = url;
    const params = { ...data };

    if (removeTrashValue) {
        Object.entries(params).forEach(item => {
            if (item[1] === undefined
                || item[1] === ''
                || (Array.isArray(item[1]) && item[1].length === 0)) {
                // @ts-ignore
                delete params[item[0]];
            }
        });
    }

    if (transformToUrl) {
        Object.entries(params).forEach((item) => {
            if (Array.isArray(item[1])) {
                item[1].forEach(val => {
                    // @ts-ignore
                    const encoded = encodeURIComponent(val);
                    const character = _url.includes('?') ? '&' : '?';
                    const key = !transformToSnakeCase ? item[0] : toSnakeCase(item[0]);
                    _url = `${_url}${character}${key}=${encoded}`;
                });
            } else {
                // @ts-ignore
                const encoded = encodeURIComponent(item[1]);
                const character = _url.includes('?') ? '&' : '?';
                const key = !transformToSnakeCase ? item[0] : toSnakeCase(item[0]);
                _url = `${_url}${character}${key}=${encoded}`;
            }
        });
    }

    return {
        params,
        url: _url
    }
}



/**
 * Map errors and display them
 *
 *
 * NOTE: It was decided that displayed message will be the one from the backend
 * @param errors
 */
export const errorManager = (errors: any) => {
    if (typeof errors === 'string') {
        NotificationManager.error(errors);
        return;
    }

    if (isLiteralObject(errors)) {
        Object.values(errors).forEach((error) => {
            errorManager(error);
        });
        return;
    }

    if (Array.isArray(errors)) {
        // Map through errors and display them
        errors.forEach((error) => {
            errorManager(error);
            /* if (Array.isArray(error)) {
              errorManager(error);
            } else if (typeof error === 'string') {
              NotificationManager.error(error);
            } */
        });
        return;
    }

    // Display Error 400 in case of no errors present or wrong type found
    NotificationManager.error(globalT('errors.400.feedback'));
};

/**
 * Deeply resolve error object for react-hook-form
 * @param errors
 * @param name
 */
export const resolveFieldError = (errors: FieldErrors<any> | undefined, name: string): FieldError | undefined => {
    if (!errors)
        return undefined;

    if (name.includes('.')) {
        const [parent, child] = name.split('.');
        return errors[parent]
            // @ts-ignore
            && errors[parent][child];
    }

    // @ts-ignore
    return errors[name];
}

/**
 * Allow accessibility for components
 * @param handler
 */
export function keyDownA11y(handler: Function | undefined) {
    return function onKeyDown(event: any) {
        if (
            handler
            && event
            && ['keydown', 'keypress'].includes(event.type)
            && ['Enter', ' '].includes(event.key)
        ) {
            handler(event);
        }
    }
}

/**
 * Check if a given string is a guid or uuid
 * @param uuid
 * @param version
 */
export const isUUID = (uuid: unknown, version: 'v1' | 'v2' | 'v3' | 'v5' | 'all' = 'all') => {
    // Gather a patter for each uuid version
    // const VALID_PATTERN = '\A[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}\z';
    const uuidVersionPattern = {
        v1: /^[0-9A-F]{8}-[0-9A-F]{4}-1[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
        v2: /^[0-9A-F]{8}-[0-9A-F]{4}-2[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
        v3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
        v4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
        v5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
        all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
    };

    return uuidVersionPattern[version].test(uuid as string);

    // return Object.values(uuidVersionPattern).some(pattern => pattern.test(uuid));
}

/**
 * Check if a given value is an entity reference
 * @param val
 */
export function isReference(val: unknown) {
    return typeof val === 'string' && val.length === 32;
}

/**
 * Check if a given value is a "safe" falsy which means falsy values without 0, -0, 0n
 * @param value
 */
export const isNotSafeFalsy = (value: any) => ![false, '', NaN, null, undefined].includes(value);

/**
 *
 * @param data
 * @param searched
 */
export const globalDeepSearch = <Data extends object>(data: Data[], searched: string | number) => {
    if (!searched) {
        return data;
    }

    const _searched = `${searched}`.toLowerCase();

    const doesMatch = (item: unknown): boolean => {
        if (typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
            return `${item}`.toLowerCase().includes(_searched);
        }

        if (Array.isArray(item)) {
            return item.some(doesMatch);
        }

        if (typeof item === 'object' && isNotSafeFalsy(item)) {
            return Object.values(item!).some(doesMatch);
        }

        return false;
    }

    return data.filter(doesMatch);
};

/**
 * Format a file size to human readable
 * @param size
 */
export const formatFileSize = (size: number) => {
    if (size > 1024 * 1024 * 1024)
        return {
            originalSize: size,
            formattedSize: `${round(size / (1024 * 1024 * 1024), 2)} GB`,
        };
    if (size > 1024 * 1024)
        return {
            originalSize: size,
            formattedSize: `${round(size / (1024 * 1024), 2)} MB`,
        };
    if (size > 1024)
        return {
            originalSize: size,
            formattedSize: `${round(size / 1024, 2)} KB`,
        };

    return {
        originalSize: size,
        formattedSize: `${round(size, 2)} Bytes`,
    }
}

/**
 * Download a content from url
 * @param url
 */
export const downloadContent = (url: string) => {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    a.href = url;

    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
};


export const globalLoadResource = <Resource>(
    resource: FetchResourceWatcher<Resource>,
    setResource: Dispatch<SetStateAction<FetchResourceWatcher<Resource>>>,
    fetchData: (...arg: any[]) => Promise<AxiosResponse<Resource, AxiosRequestConfigExtended>>
) => {
    setResource({
        ...resource,
        loading: true,
        watcher: Math.random().toString()
    })
    fetchData()
        .then((res) => {
            setResource({
                error: null,
                content: res.data,
                loading: false,
                watcher: Math.random().toString()
            })
        })
        .catch((err) => {
            setResource({
                error: err,
                content: null,
                loading: false,
                watcher: Math.random().toString()
            })
        })
}

export const numberWithCommas = (x: any) => {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
}