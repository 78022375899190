import './style.css';
import rpnClient from '../../network';
import React, { useEffect, useState } from 'react';
import IntlMessage from '../../components/IntlMessage';
import { formatNumber, globalLoadResource, numberWithCommas, round } from '../../commons/helpers/funcHelper';
import Card, {CardBody, CardHeader, CardLabel, CardTitle} from "../../components/bootstrap/Card";
import useAuthUserStore from '../../store/slices/authUser';
import { globalT } from '../../lang';
import useSettingsStore from '../../store/slices/settings';

const Statistics = () => {

    const appCurrency = useSettingsStore(s => s.appCurrency);
    const authUserData = useAuthUserStore(s => s.content!);
    const [statistics, setStatistics] = useState<any>({});

    useEffect(() => {
        loadStatistics();
    }, []);

    const loadStatistics = () => {
        globalLoadResource<{ content: { qpi: string, fixed: string, balance: string, members: string, activeMembers: string, deadMembers: string, probationMembers: string, inactiveMembers: string }}>(
            statistics,
            setStatistics,
            rpnClient.auth.getStatisctics
        )
    }

    return (
        <div className='row w-100 mt-5'>
            <div className='col-12 col-md-4 col-lg-4 item'>
                <Card className='w-100 card-background'>
                    <CardHeader className='pb-0'>
                        <CardLabel>
                            <CardTitle className='text-white'>
                                <IntlMessage id={'stat.members'} />
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody className="">
                        <p className='mb-0 text-white' style={{ fontSize: 28, textAlign: 'right', fontWeight: 'bold' }}>
                            {numberWithCommas(statistics?.content?.members)}
                        </p>
                    </CardBody>
                </Card>
            </div>

            <div className='col-12 col-md-4 col-lg-4 item'>
                <Card className='w-100 card-background'>
                    <CardHeader className='pb-0'>
                        <CardLabel>
                            <CardTitle className='text-white'>
                                { authUserData?.community ? <IntlMessage id={'stat.balance'} /> : <IntlMessage id={'stat.communities'} />}
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody className="">
                        <p className='mb-0 text-white' style={{ fontSize: 28, textAlign: 'right', fontWeight: 'bold' }}>
                            {authUserData?.community ? formatNumber(round(Number(statistics?.content?.balance)), globalT(appCurrency.symbol), appCurrency.placement) : numberWithCommas(statistics?.content?.balance)}
                        </p>
                    </CardBody>
                </Card>
            </div>

            <div className='col-12 col-md-4 col-lg-4 item'>
                <Card className='w-100 card-background'>
                    <CardHeader className='pb-0'>
                        <CardLabel>
                            <CardTitle className='text-white'>
                                <IntlMessage id={'stat.qpi'} />
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody className="">
                        <p className='mb-0 text-white' style={{ fontSize: 28, textAlign: 'right', fontWeight: 'bold' }}>
                            {formatNumber(round(Number(statistics?.content?.qpi)), globalT(appCurrency.symbol), appCurrency.placement)}
                        </p>
                    </CardBody>
                </Card>
            </div>

            <div className='col-12 col-md-6 col-lg-6 item'>
                <Card className='w-100 card-background'>
                    <CardHeader className='pb-0'>
                        <CardLabel>
                            <CardTitle className='text-white'>
                                <IntlMessage id={'stat.dead'} />
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody className="">
                        <p className='mb-0 text-white' style={{ fontSize: 28, textAlign: 'right', fontWeight: 'bold' }}>
                            {numberWithCommas(statistics?.content?.deadMembers)}
                        </p>
                    </CardBody>
                </Card>
            </div>

            <div className='col-12 col-md-6 col-lg-6 item'>
                <Card className='w-100 card-background'>
                    <CardHeader className='pb-0'>
                        <CardLabel>
                            <CardTitle className='text-white'>
                                <IntlMessage id={'stat.fixed'} />
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody className="">
                        <p className='mb-0 text-white' style={{ fontSize: 28, textAlign: 'right', fontWeight: 'bold' }}>
                            {formatNumber(round(Number(statistics?.content?.fixed)), globalT(appCurrency.symbol), appCurrency.placement)}
                        </p>
                    </CardBody>
                </Card>
            </div>

            <div className='col-12 col-md-4 col-lg-4 item'>
                <Card className='w-100 card-background'>
                    <CardHeader className='pb-0'>
                        <CardLabel>
                            <CardTitle className='text-white'>
                                <IntlMessage id={'stat.active'} />
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody className="">
                        <p className='mb-0 text-white' style={{ fontSize: 28, textAlign: 'right', fontWeight: 'bold' }}>
                            {numberWithCommas(statistics?.content?.activeMembers)}
                        </p>
                    </CardBody>
                </Card>
            </div>

            <div className='col-12 col-md-4 col-lg-4 item'>
                <Card className='w-100 card-background'>
                    <CardHeader className='pb-0'>
                        <CardLabel>
                            <CardTitle className='text-white'>
                                <IntlMessage id={'stat.inactive'} />
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody className="">
                        <p className='mb-0 text-white' style={{ fontSize: 28, textAlign: 'right', fontWeight: 'bold' }}>
                            {numberWithCommas(statistics?.content?.inactiveMembers)}
                        </p>
                    </CardBody>
                </Card>
            </div>

            <div className='col-12 col-md-4 col-lg-4 item'>
                <Card className='w-100 card-background'>
                    <CardHeader className='pb-0'>
                        <CardLabel>
                            <CardTitle className='text-white'>
                                <IntlMessage id={'stat.probation'} />
                            </CardTitle>
                        </CardLabel>
                    </CardHeader>

                    <CardBody className="">
                        <p className='mb-0 text-white' style={{ fontSize: 28, textAlign: 'right', fontWeight: 'bold' }}>
                            {numberWithCommas(statistics?.content?.probationMembers)}
                        </p>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default Statistics;
